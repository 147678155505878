<template>
  <v-card>
    <v-card-title :class="contractColor">
      <span class="headline white--text">
        {{ title }}
      </span>
      <v-spacer/>
      <div class="ma-0 pa-0" style="width: 325px">
        <v-select
          v-if="selectedGroupingKey"
          :items="selectableGroupingKeys"
          return-object
          :value="selectedGroupingKey"
          @input="setGroupingKey"
          rounded
          dark
          dense
          outlined
          class="mb-n6 mr-2"
          :label="$t('filter')"
          :disabled="resourcesLoading"
        >
          <template #item="{ item, on }">
            <v-list-item v-on="on" :disabled="item.reconciled.size >= item.total">
              <v-icon class="mr-2">{{ticketGroupIconFor(item.type)}}</v-icon>
              <span>{{item.display}}</span>
              <span class="grey--text ml-2">{{`| ${item.reconciled.size}/${item.total}`}}</span>
            </v-list-item>
          </template>
          <template #selection="{ item } ">
            <template v-if="item.type !== ticketGroupTypes.none">
              <v-icon class="mr-2" color="white">{{ticketGroupIconFor(item.type)}}</v-icon>
              <span class="white--text">{{item.display}}</span>
            </template>
            <span v-else class="white--text">{{item.display}}</span>
          </template>
        </v-select>
      </div>
      <BaseDialogActions hideRefresh/>
    </v-card-title>
    <v-card-text>
      <FormWrapper
      v-if="!formContentDisabled"
      ref="formWrapper"
      formRef="ticketReconciliationForm"
      :buttonText="$t('reconcile')"
      :buttonColor="contractColor"
      @submit="submitAndAdvance"
      :disabled="formSubmitDisabled"
      >
        <template #right-action>
          <Icon
          v-if="imageData.length > 0 && !clickedThrough"
          margin="mr-2"
          icon="mdi-alert-circle-outline"
          iconColor="error"
          large
          :small="false"
          :tooltipText="$t('mustViewAllTicketsBeforeReconciling')"
          />
          <v-btn :disabled="formContentDisabled || resourcesLoading" class="mr-2 black--text" color="#eee" @click="advancePage">{{$t('skip')}}</v-btn>
        </template>
        <v-row class="mt-7 mx-1">
          <v-col sm="12" md="6">
            <TicketImageViewer
            v-if="currentTicket"
            height="70vh"
            maxHeight="70vh"
            :ticketNumber="currentTicket?.ticketNumber ?? -1"
            :ticketImageData="imageData ?? []"
            emitBehavior="always"
            includeImageData
            @data-selected="setSelectedImageData"
            @warnings="setWarnings"
            @clicked-through="setClickedThrough"
            @page-changed="validateExtTicketNumber1()"
            @delete="deleteTicketImage"
            :confidenceWarningThreshold="{ low: 0.5, high: 0.25 }"
            :loadingIndicator="imageDataLoader.loading"
            />
          </v-col>
          <v-col xs="12" sm="12" md="6">
            <v-row class="pb-6">
              <v-card style="width: 100%" elevation="1" :disabled="formContentDisabled">
                <v-progress-linear v-if="ticketsDataLoader.loading" indeterminate background-color="white"/>
                <v-simple-table style="width: 100%; table-layout: fixed;">
                  <tbody style="table-layout: fixed;">
                    <tr v-for="(c, i) in chunk(readonlyTicketFields, 2)" :key="`rtf-r-${i}`">
                      <td>{{c?.[0]?.label}}</td>
                      <td>{{c?.[0]?.value}}</td>
                      <td>{{c?.[1]?.label}}</td>
                      <td>{{c?.[1]?.value}}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-row>
            <v-row class="pb-6">
              <span v-if="imageDataLoader.loading">
                <v-progress-circular indeterminate color="grey" size="36" class="mx-2"/>
                {{$t('loadingTicketImageData')}}
              </span>
              <div v-else-if="imageDataLoader.error">
                <Icon
                icon="mdi-cancel"
                iconColor="error"
                :small="false"
                large
                :tooltipText="$t('errorTicketImageData')"
                />
                {{$t('errorTicketImageData')}}
              </div>
              <div v-else>
                <span v-if="warnings.length === 0">
                  <Icon
                  :icon="someImageHasData ? 'mdi-check' : 'mdi-cancel'"
                  :iconColor="someImageHasData ? 'success' : 'grey'"
                  :small="false"
                  large
                  :tooltipText="someImageHasData ? $t('ticketImageDataValid') : $t('ticketImageNoData')"
                  />
                  {{ someImageHasData ? $t('recognizedDataSummary', { count: imagesWithDataCount }) : $t('noRecognizedData')}}
                </span>
                <span v-else v-for="(warning, index) in warnings" :key="index" class="pr-2 pl-1">
                  <Icon
                  icon="mdi-alert"
                  :iconColor="warning.severity === 'high' ? 'error' : 'warning'"
                  :small="false"
                  large
                  :tooltipText="warning.message"
                  />
                  {{warning.message}}
                </span>
              </div>
            </v-row>

            <!-- Known ticket fields -->
            <template v-if="hasTracts">
              <v-row no-gutters>
                <v-autocomplete
                ref="tractInput"
                :label="$t('tract')"
                :loading="ticketsDataLoader.loading || availableTracts.length === 0"
                :items="availableTracts"
                v-model="knownTicketFields.tractId"
                item-value="tractId"
                >
                <template #item="{ item, on, attrs }">
                  <v-list-item v-on="on" v-bind="attrs">
                    {{ item.name }} || {{item.tractType}}
                  </v-list-item>
                </template>
                <template #selection="{ item }">
                  <v-icon small class="pr-1">mdi-crosshairs-gps</v-icon>
                  {{ item.name }} || {{item.tractType}}
                </template>
                <template #append-outer v-if="knownTicketFields.tractId !== currentTicket.tractId">
                  <Icon
                  icon="mdi-asterisk"
                  iconColor="primary"
                  :tooltipText="$t('thisXDiffersResetContractProduct', { x: $t('tract').toLocaleLowerCase() })"
                  @icon-clicked="resetTicketContract(currentTicket)"
                  small/>
                </template>
                </v-autocomplete>
              </v-row>

              <v-row no-gutters>
                <v-autocomplete
                ref="contractInput"
                data-testid="trm-contract-input"
                :label="$t('contract')"
                :loading="ticketsDataLoader.loading || availableContractsOnTract.length === 0"
                :items="availableContractsOnTract"
                v-model="knownTicketFields.contractId"
                item-value="contractId"
                :filter="(item, queryText) => lowercaseEquals(item.tract, queryText) || lowercaseEquals(item.destination, queryText) || lowercaseEquals(item.setting, queryText) || lowercaseEquals(item.account, queryText)"
                :rules="[id => availableContractsOnTract.length === 0 || availableContractsOnTract.some(c => c.contractId === id) || $t('selectContract')]"
                >
                <template #item="{ item, on, attrs }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <template v-for="cf in contractFields(item)">
                      <v-icon :key="`cf-${cf.icon}-icon`" small class="pa-1">{{cf.icon}}</v-icon>
                      {{cf.value}}
                    </template>
                  </v-list-item>
                </template>
                <template #selection="{ item }">
                  <template v-for="cf in contractFields(item)">
                    <v-icon :key="`cf-${cf.icon}-icon-sel`" small class="pa-1">{{cf.icon}}</v-icon>
                    {{cf.value}}
                  </template>
                </template>
                <template #append-outer v-if="knownTicketFields.contractId && knownTicketFields.contractId !== currentTicket.contractId">
                  <Icon
                  icon="mdi-asterisk"
                  iconColor="primary"
                  :tooltipText="$t('thisXDiffersResetContractProduct', { x: $t('contract').toLocaleLowerCase() })"
                  @icon-clicked="resetTicketContract(currentTicket)"
                  small/>
                </template>
                </v-autocomplete>
              </v-row>
            </template>
            <template v-else>
              <v-row no-gutters>
                <v-autocomplete
                  ref="fromAccountInput"
                  :label="$t('fromAccount')"
                  :loading="ticketsDataLoader.loading || availableFromAccounts.length === 0"
                  :items="availableFromAccounts"
                  item-text="fromAccount"
                  item-value="fromAccountId"
                  v-model="knownTicketFields.fromAccountId"
                >
                  <template #selection="{ item }">
                    <span>{{ item.fromAccount }}</span>
                    <span class="ml-2 grey--text" v-if="knownTicketFields.fromAccountId !== currentTicket.fromAccountId">
                      &larr;
                      {{ currentTicket.fromAccount }}
                    </span>
                  </template>
                  <template #append-outer v-if="knownTicketFields.fromAccountId !== currentTicket.fromAccountId">
                    <Icon
                    icon="mdi-asterisk"
                    iconColor="primary"
                    :tooltipText="$t('thisXDiffersResetContractProduct', { x: $t('field').toLocaleLowerCase() })"
                    @icon-clicked="resetTicketContract(currentTicket)"
                    small/>
                  </template>
                </v-autocomplete>
              </v-row>
              <v-row dense>
                <v-col cols="6">
                  <v-autocomplete
                    ref="destinationAccountInput"
                    :label="$t('destinationAccount')"
                    :loading="ticketsDataLoader.loading || availableDestinationAccounts.length === 0"
                    :items="availableDestinationAccounts"
                    item-text="destinationAccount"
                    item-value="destinationAccountId"
                    v-model="knownTicketFields.destinationAccountId"
                  >
                    <template #selection="{ item }">
                      <span>{{ item.destinationAccount }}</span>
                      <span class="ml-2 grey--text" v-if="knownTicketFields.destinationAccountId !== currentTicket.destinationAccountId">
                        &larr;
                        {{ currentTicket.destination }}
                      </span>
                    </template>
                    <template #append-outer v-if="knownTicketFields.destinationAccountId !== currentTicket.destinationAccountId">
                      <Icon
                      icon="mdi-asterisk"
                      iconColor="primary"
                      :tooltipText="$t('thisXDiffersResetContractProduct', { x: $t('field').toLocaleLowerCase() })"
                      @icon-clicked="resetTicketContract(currentTicket)"
                      small/>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    ref="contractAccountInput"
                    :label="$t('contractAccount')"
                    :loading="ticketsDataLoader.loading || availableContractAccounts.length === 0"
                    :items="availableContractAccounts"
                    item-text="account"
                    item-value="accountId"
                    v-model="knownTicketFields.contractAccountId"
                  >
                    <template #selection="{ item }">
                      <span>{{ item.account }}</span>
                      <span class="ml-2 grey--text" v-if="knownTicketFields.contractAccountId !== currentTicket.accountId">
                        &larr;
                        {{ currentTicket.account }}
                      </span>
                    </template>
                    <template #append-outer v-if="knownTicketFields.contractAccountId !== currentTicket.accountId">
                      <Icon
                      icon="mdi-asterisk"
                      iconColor="primary"
                      :tooltipText="$t('thisXDiffersResetContractProduct', { x: $t('field').toLocaleLowerCase() })"
                      @icon-clicked="resetTicketContract(currentTicket)"
                      small/>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </template>

            <v-row dense>
              <v-col cols="6">
                <v-autocomplete
                  ref="productInput"
                  :label="$t('product')"
                  :loading="ticketsDataLoader.loading"
                  :items="currentTicketProducts"
                  v-model="knownTicketFields.productId"
                  item-value="productId"
                  item-text="name"
                  :rules="((currentTicketProducts.length === 0 && selectedContract?.acceptsAnyLoad) || currentTicketProducts.every(p => p.productId !== knownTicketFields.productId)) ? [$t('selectAProduct')] : []"
                  :filter="(_, queryText, itemText) => itemText.toLowerCase().indexOf(queryText.toLowerCase()) !== -1">
                  <template #selection="{ item }">
                    <span>{{ item.name }}</span>
                    <span class="ml-2 grey--text" v-if="currentTicketProducts.length > 0 && knownTicketFields.productId !== currentTicket.productID">
                      &larr;
                      {{ currentTicket.product }}
                    </span>
                  </template>
                  <template #append-outer v-if="currentTicketProducts.length > 0 && knownTicketFields.productId !== currentTicket.productID">
                    <Icon
                      icon="mdi-asterisk"
                      iconColor="primary"
                      :tooltipText="$t('thisXDiffersResetContractProduct', { x: $t('product').toLocaleLowerCase() })"
                      @icon-clicked="resetTicketContract(currentTicket)"
                      small/>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-text-field
                ref="ext1Input"
                :disabled="formContentDisabled"
                :rules="fieldSet.extTicketNumber1.rules"
                v-model="fieldSet.extTicketNumber1.value"
                :label="$t(fieldSet.extTicketNumber1.label)"
                :required="fieldSet.extTicketNumber1.required"
                :error-messages="!ext1Valid && !ext1Unchecked ? [$t('ticketNumberNotUniqueForDestination')] : undefined"
                @blur="validateExtTicketNumber1()">
                  <template #append>
                    <v-progress-circular v-if="ext1Validator.loading" size="24" indeterminate color="grey"/>
                    <Icon
                    v-else
                    icon="mdi-checkbox-multiple-marked-circle"
                    :iconColor="!ext1Unchecked ? ext1Valid ? 'success' : 'error' : 'grey'"
                    :disabled="!fieldSet.extTicketNumber1.value"
                    :small="false"
                    :tooltipText="ext1Unchecked ? $t('validateTicketNumber') : ext1Valid ? $t('ticketNumberUniqueForDestination') : `${$t('ticketNumberNotUniqueForDestination')}\n${$t('clickIconForDetails')}`"
                    @icon-clicked="() => { ext1Valid || (ext1Unchecked) ? validateExtTicketNumber1() : openTicketDuplicatesDialog() }"
                    />
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                ref="inWeightInput"
                type="number"
                hide-spin-buttons
                :disabled="formContentDisabled"
                :rules="fieldSet.inWeight.rules"
                v-model="fieldSet.inWeight.value"
                :label="$t(fieldSet.inWeight.label)"
                :required="fieldSet.inWeight.required">
                  <template #append>
                    <span style="font-family: 'IBM Plex Mono', monospace; color: #777">
                      {{intOrUndefined(fieldSet.inWeight.value) !== undefined ? ((fieldSet.inWeight.value ?? 0) / 2000).toFixed(3) : ''}}
                    </span>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                ref="outWeightInput"
                type="number"
                hide-spin-buttons
                :disabled="formContentDisabled"
                :rules="fieldSet.outWeight.rules"
                v-model="fieldSet.outWeight.value"
                :label="$t(fieldSet.outWeight.label)"
                :required="fieldSet.outWeight.required">
                  <template #append>
                    <span style="font-family: 'IBM Plex Mono', monospace; color: #777">
                      {{intOrUndefined(fieldSet.outWeight.value) !== undefined ? ((fieldSet.outWeight.value ?? 0) / 2000).toFixed(3) : ''}}
                    </span>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                ref="defectWeightInput"
                type="number"
                hide-spin-buttons
                :disabled="formContentDisabled"
                :rules="fieldSet.defectWeight.rules"
                v-model="fieldSet.defectWeight.value"
                :label="$t(fieldSet.defectWeight.label)"
                :required="fieldSet.defectWeight.required">
                  <template #append>
                    <span style="font-family: 'IBM Plex Mono', monospace; color: #777">
                      {{intOrUndefined(fieldSet.defectWeight.value) !== undefined ? ((fieldSet.defectWeight.value ?? 0) / 2000).toFixed(3) : ''}}
                    </span>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                disabled
                readonly
                :label="$t('netWeight')"
                :rules="[w => w > 0]"
                v-model="netWeight">
                  <template #append>
                    <span
                    style="font-family: 'IBM Plex Mono', monospace; color: #777">
                      {{intOrUndefined(netWeight) !== undefined ? ((netWeight ?? 0) / 2000).toFixed(3) : ''}}
                    </span>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="3" class="pr-4">
                <v-select
                :disabled="formContentDisabled"
                :value="formContentDisabled ? undefined : fieldSet.weighedOutAt.selectedSource"
                :items="fieldSet.weighedOutAt.sources"
                :label="$t(fieldSet.weighedOutAt.label)"
                item-text="label"
                item-value="index"
                :item-disabled="i => !i.hasValue"
                return-object
                @input="source => setFieldSource(fieldSet.weighedOutAt, source)">
                  <template #item="{ item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <span>{{item.label}}</span>
                      <Icon
                      v-if="item.invalid"
                      icon="mdi-alert-outline"
                      iconColor="error"
                      :small="false"
                      :tooltipText="$t('fieldValueIsInvalid')"
                      margin="ml-1"
                      />
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col :cols="5" class="px-1" v-if="!ticketsDataLoader.loading">
                <DatePicker
                ref="weighedOutAtInput"
                v-if="fieldSet.weighedOutAt.value && showDatePicker"
                dateLabel="Date"
                :focusInput="focusWeighedOutAtKey"
                :startDate="fieldSet.weighedOutAt.value ? isoDateToDateTime(fieldSet.weighedOutAt.value).date : undefined"
                @date-picked="date => setFieldDate(fieldSet.weighedOutAt, date)"
                :invalid="fieldSet.weighedOutAt.invalid"
                :disabled="formContentDisabled"/>
              </v-col>
              <v-col :cols="4" class="pl-1" v-if="!ticketsDataLoader.loading">
                <TimePicker
                v-if="fieldSet.weighedOutAt.value && showDatePicker"
                :label="$t('time')"
                :startTime="fieldSet.weighedOutAt.value ? isoDateToDateTime(fieldSet.weighedOutAt.value).time : undefined"
                @time-picked="time => setFieldTime(fieldSet.weighedOutAt, time)"
                :disabled="formContentDisabled">
                  <template #append-outer>
                    <Icon
                    v-if="weighedOutBeforeLoadCreated"
                    icon="mdi-alert"
                    iconColor="warning"
                    :small="false"
                    :tooltipText="$t('weighedOutBeforeLoadCreatedClickReset')"
                    @icon-clicked="setFieldSource(fieldSet.weighedOutAt, fieldSet.weighedOutAt.sources[1])"
                    />
                  </template>
                </TimePicker>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </FormWrapper>
      <div v-else style="height: 70vh; text-align: center; user-select: none;">
        <v-icon size="50vh" class="mt-12" style="opacity: 0.15;">{{landingText.icon}}</v-icon>
        <br>
        <span class="h3 grey--text">{{landingText.text}}</span>
      </div>
    </v-card-text>
    <Dialog :stateId="dialogId">
      <DuplicateTickets
        v-if="!ext1Valid"
        :currentTicket="currentTicket"
        :duplicateTickets="ext1Duplicates"
      />
    </Dialog>
  </v-card>
</template>

<script>
import moment from 'moment'
import { groupBy, chunk } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { ticketImageClient, ticketRecognitionClient } from '../../../utils/TicketImages.js'
import { ResourceLoader } from '../../../utils/ResourceLoader'
import { tickTock, uniqueDialogId, colorClassForContractMode } from '../../../utils/componentHelpers'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { ContractMode, ContractStatus, TractTypeCategory } from '../../../utils/Enumerations.js'
import i18n from '@/i18n'

export default {
  name: 'TicketReconciliation',
  components: {
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    DatePicker: () => import('@/components/helper/DatePicker.vue'),
    TimePicker: () => import('@/components/helper/TimePicker.vue'),
    TicketImageViewer: () => import('@/components/ticket/TicketImageViewer.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    DuplicateTickets: () => import('./DuplicateTickets.vue'),
    Dialog: () => import('@/components/Dialog.vue')
  },

  props: {
    contractMode: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    dialogId: uniqueDialogId('ticket-reconciliation'),
    allTickets: [],
    currentTickets: [],
    externalContracts: [],
    fieldSet: {},
    knownTicketFields: {
      tractId: undefined,
      contractId: undefined,
      fromAccountId: undefined,
      destinationAccountId: undefined,
      contractAccountId: undefined,
      productId: undefined
    },
    ticketGroupingKeys: [],
    selectedGroupingKey: undefined,
    currentTicketIndex: 0,
    imageData: [],
    selectedImageData: {},
    warnings: [],
    clickedThrough: true,
    showDatePicker: true,
    ticketsDataLoader: ResourceLoader.empty,
    imageDataLoader: ResourceLoader.empty,
    ext1Validator: ResourceLoader.empty,
    ext1Checks: [],
    netWeight: 0,
    focusWeighedOutAtKey: false,
    loading: false
  }),

  watch: {
    async selectedDestinationAccountId (id, oldId) {
      if (id !== oldId && id !== undefined) {
        await this.validateTicketNumberField()
      }
    },

    imagesCount (count) {
      if (count === 1) {
        this.$nextTick(() => {
          this.setClickedThrough(true)
        })
      }
    },

    knownTicketFields: {
      handler (fields) {
        if (!fields) return

        if (this.hasFromAccount) {
          const { fromAccountId, destinationAccountId, contractAccountId } = fields
          if (this.selectedContract?.fromAccountId === fromAccountId && this.selectedContract?.destinationAccountId === destinationAccountId && this.selectedContract?.accountId === contractAccountId) return

          const fixedFields = (this.selectedContract?.fromAccountId !== fromAccountId)
            ? { fromAccountId }
            : (this.selectedContract?.destinationAccountId !== destinationAccountId)
              ? { fromAccountId, destinationAccountId }
              : (this.selectedContract?.contractAccountId !== contractAccountId)
                ? { fromAccountId, destinationAccountId, contractAccountId }
                : undefined

          if (fixedFields === undefined) return

          const bestMatchContract = this.matchFieldsAgainstContracts(this.currentContracts, fixedFields, this.currentTicket)
          this.knownTicketFields.fromAccountId = bestMatchContract?.fromAccountId
          this.knownTicketFields.destinationAccountId = bestMatchContract?.destinationAccountId
          this.knownTicketFields.contractAccountId = bestMatchContract?.accountId
          this.knownTicketFields.contractId = bestMatchContract?.contractId
        } else {
          const { tractId } = fields
          if (this.selectedContract?.tractId === tractId) return

          const fixedFields = { tractId }

          const bestMatchContract = this.matchFieldsAgainstContracts(this.currentContracts, fixedFields, this.currentTicket)
          this.knownTicketFields.tractId = bestMatchContract?.tractId
          this.knownTicketFields.contractId = bestMatchContract?.contractId
        }
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('product', ['allProducts']),
    ...mapGetters('tract', ['allTracts']),
    ...mapGetters('user', ['companyInfo']),

    readonlyTicketFields () {
      return (this.hasTracts)
        ? [
          {
            label: this.$t('ticketNumber'),
            value: this.currentTicket?.ticketNumber
          },
          {
            label: this.$t('logger'),
            value: this.currentTicket?.logger
          },
          {
            label: this.$t('created'),
            value: this.currentTicket?.loadCreatedAt ? utcToLocalDate(this.currentTicket.loadCreatedAt, 'L - LT') : undefined
          },
          {
            label: this.$t('driver'),
            value: this.currentTicket?.driver ?? this.$t('notAvailable')
          },
          {
            label: this.$t('trailerId'),
            value: this.currentTicket?.trailerIdentifier
          }
        ] : [
          {
            label: this.$t('ticketNumber'),
            value: this.currentTicket?.ticketNumber
          },
          {
            label: this.$t('driver'),
            value: this.currentTicket?.driver ?? this.$t('notAvailable')
          },
          {
            label: this.$t('created'),
            value: this.currentTicket?.loadCreatedAt ? utcToLocalDate(this.currentTicket.loadCreatedAt, 'L - LT') : undefined
          },
          {
            label: this.$t('trailerId'),
            value: this.currentTicket?.trailerIdentifier
          }

        ]
    },

    contractColor () {
      return colorClassForContractMode(this.contractMode.value)
    },

    currentTicket () {
      return this.currentTickets?.[this.currentTicketIndex] ?? {}
    },
    imagesCount () {
      return this.imageData?.length
    },
    imagesWithDataCount () {
      return this.imageData?.filter(d => d.fields !== undefined).length ?? 0
    },
    someImageHasData () {
      return this.imagesWithDataCount > 0
    },
    title () {
      if (this.formContentDisabled) return this.$t('noTicketsToReconcile')
      const prefix = (this.selectedGroupingKey.type !== this.ticketGroupTypes.none)
        ? this.$t('reconcilingTicketsForX', { x: this.selectedGroupingKey.display })
        : this.$t('reconcilingTickets')
      return `${prefix} (${this.currentTicketIndex + 1}/${this.currentTickets.length})`
    },
    productLabel () {
      return (this.currentTicket.pieces !== undefined)
        ? `${this.currentTicket.product} (${this.currentTicket.pieces})`
        : this.currentTicket.product
    },
    formContentDisabled () {
      return this.currentTickets.length === 0 || this.currentTicketIndex >= this.currentTickets.length
    },
    formSubmitDisabled () {
      return !this.clickedThrough || this.ext1Unchecked
    },
    ticketGroupTypes () {
      return {
        tract: 'tractId',
        loggerAccount: 'loggerAccountId',
        destinationAccount: 'destinationAccountId',
        none: ''
      }
    },
    ticketsRemainingInGroup () {
      if (!this.selectedGroupingKey) return 0
      return Math.max(0, this.selectedGroupingKey.total - this.selectedGroupingKey.reconciled.size)
    },
    landingText () {
      if (this.ticketsDataLoader.loading) {
        return {
          icon: 'mdi-clipboard-outline',
          text: this.$t('loadingTicketsForReconciliation')
        }
      }
      return {
        icon: this.ticketsRemainingInGroup ? 'mdi-clipboard-text-outline' : 'mdi-clipboard-check-outline',
        text: this.ticketsRemainingInGroup === 0
          ? (this.selectedGroupingKey?.type)
            ? this.$t('noTicketsToReconcileForX', { x: this.selectedGroupingKey.display })
            : this.$t('noTicketsToReconcile')
          : (this.selectedGroupingKey?.type)
            ? this.$t('nTicketsLeftToReconcileForX', { n: this.ticketsRemainingInGroup, x: this.selectedGroupingKey.display })
            : this.$t('nTicketsLeftToReconcile', { n: this.ticketsRemainingInGroup })
      }
    },

    selectableGroupingKeys () {
      return this.ticketGroupingKeys
    },

    hasTracts () {
      // TODO: Update when byproduct purchase contracts can use tracts.
      return this.contractMode === ContractMode.Logs
    },

    hasFromAccount () {
      // TODO: Update when byproduct purchase contracts can be created without a from account.
      return this.contractMode === ContractMode.Byproducts || this.contractMode === ContractMode.LogYardSale
    },

    stumpageTracts () {
      if (!this.hasTracts) return []

      return this.allTracts.filter(tract => tract.type.category === TractTypeCategory.Stumpage.value)
    },

    weighedOutBeforeLoadCreated () {
      const loadCreatedAt = moment.utc(this.currentTicket.loadCreatedAt)
      const fieldWeighedOutAt = this.fieldSet.weighedOutAt.value
      let weighedOutUtc

      if (this.fieldSet.weighedOutAt.selectedSource.type === 'ocr') { // source is ocr - must have destination account timezone offset applied
        const offset = this.selectedContract?.destinationAccountTimeZoneOffset
        weighedOutUtc = moment.utc(fieldWeighedOutAt).add(0 - offset, 'hour')
        weighedOutUtc = weighedOutUtc.local().isDST() ? weighedOutUtc.add(-1, 'hour') : weighedOutUtc
      } else { // source is loadCreatedAtLocal - must convert back to utc in order to compare
        const utcOffset = moment().utcOffset() / 60
        weighedOutUtc = moment.utc(fieldWeighedOutAt).add('hour', utcOffset * -1)
      }

      return loadCreatedAt.isAfter(weighedOutUtc.toString())
    },

    availableTracts () {
      return (this.hasTracts)
        ? Object.values(groupBy(this.currentContracts.map(c => ({
          tractId: c.tractId,
          name: c.tract,
          tractType: c.tractType
        })), c => c.tractId)).map(tg => tg?.[0])
        : []
    },

    availableContractsOnTract () {
      return (this.hasTracts) ? this.currentContracts.filter(c => c.tractId === this.knownTicketFields.tractId) : []
    },

    availableFromAccounts () {
      return (this.hasFromAccount)
        ? Object.values(groupBy(this.currentContracts.map(c => ({
          fromAccountId: c.fromAccountId,
          fromAccount: c.fromAccount
        })), c => c.fromAccountId)).map(cg => cg?.[0])
        : []
    },

    availableDestinationAccounts () {
      return (this.hasFromAccount)
        ? Object.values(groupBy(this.currentContracts.filter(c => c.fromAccountId === this.knownTicketFields.fromAccountId).map(c => ({
          destinationAccountId: c.destinationAccountId,
          destinationAccount: c.destination
        })), c => c.destinationAccountId)).map(cg => cg?.[0])
        : []
    },

    availableContractAccounts () {
      return (this.hasFromAccount)
        ? Object.values(groupBy(this.currentContracts.filter(c => c.fromAccountId === this.knownTicketFields.fromAccountId && c.destinationAccountId === this.knownTicketFields.destinationAccountId).map(c => ({
          accountId: c.accountId,
          account: c.account,
          contractId: c.contractId
        })), c => c.accountId)).map(cg => cg?.[0])
        : []
    },

    currentContracts () {
      return this.externalContracts.filter(c => c.status === ContractStatus.Open.value || c.contractId === this.currentTicket.contractId)
    },

    selectedContract () {
      return this.currentContracts.find(c => c.contractId === this.knownTicketFields.contractId)
    },

    selectedDestinationAccountId () {
      return this.selectedContract?.destinationAccountId
    },

    currentTicketProducts () {
      return (this.selectedContract?.acceptsAnyLoad)
        ? this.allProducts
        : this.allProducts.filter(p => this.selectedContract?.productIds.some(id => p.productId === id))
    },

    ext1Duplicates () {
      const ext1 = this.fieldSet.extTicketNumber1.value
      const entry = this.ext1Checks.find(ec => ec.destinationAccountId === this.selectedDestinationAccountId && ec.ext1 === ext1)
      return entry?.duplicates
    },
    ext1Valid () {
      return this.ext1Duplicates?.length === 0
    },
    ext1Unchecked () {
      return this.ext1Duplicates === undefined
    },
    resourcesLoading () {
      return this.ticketsDataLoader.loading || this.imageDataLoader.loading || this.loading
    }
  },

  async created () {
    this.ticketsDataLoader = new ResourceLoader(async () => {
      this.externalContracts = (await this.fetchContracts({
        includeOpen: true,
        includeProduction: this.contractMode === ContractMode.Logs,
        includeWoodsSale: this.contractMode === ContractMode.Logs,
        includeByProduct: this.contractMode === ContractMode.Byproducts,
        includeLogYardSale: this.contractMode === ContractMode.LogYardSale
      })).filter(c => c.isExternal)

      this.allTickets = (await this.fetchTicketsForReconciliation(
        {
          isLogs: this.contractMode === ContractMode.Logs,
          isByproduct: this.contractMode === ContractMode.Byproducts,
          isLogYardSale: this.contractMode === ContractMode.LogYardSale
        }))
        .sort((a, b) => (this.hasTracts) ? a.tract.localeCompare(b.tract) : a.destination.localeCompare(b.destination))

      this.ticketGroupingKeys = this.getTicketGroupingKeys(this.allTickets)
      this.selectedGroupingKey = this.ticketGroupingKeys?.[0]
      this.currentTickets = this.allTickets
      await this.fetchProducts({ includeProduction: this.contractMode !== ContractMode.Byproducts, includeByProducts: this.contractMode === ContractMode.Byproducts })
      if (this.currentTickets.length > 0) {
        this.loading = true
        await this.setupTicket(this.currentTickets[0])
        this.loading = false
      }
    })

    this.imageDataLoader = new ResourceLoader(async (ticket) => {
      const imageData = (await ticketRecognitionClient.getRecognitionData(ticket?.ticketId))
      this.imageData = imageData.sort(this.sortFieldsByDataQuality)
      if (this.imageData.length === 0) this.applyDataToFieldsOfType('ocr', {})
    })

    this.ext1Validator = new ResourceLoader(this.validateTicketNumberField)

    const makeField = ({ type, required, label, sources, rules = undefined, onSet = undefined, onSubmit = undefined }) => {
      const allRules = [
        (required)
          ? v => (v === undefined || v === null || v === '') ? this.$t('enterValue') : true
          : undefined,
        ...(Array.isArray(rules) ? rules : [rules])
      ].filter(r => r !== undefined)
      return {
        type,
        label,
        required,

        _value: undefined,
        get value () {
          return this._value
        },
        set value (v) {
          let iv = false
          for (const rule of allRules) {
            const r = rule(v)
            if (r !== true) {
              iv = true
              break
            }
          }
          this.invalid = iv
          this._value = v
          if (onSet) {
            for (const fn of (Array.isArray(onSet) ? onSet : [onSet])) {
              fn(this._value)
            }
          }
        },

        sources: Array.isArray(sources) ? sources : undefined,
        selectedSource: Array.isArray(sources) ? sources[0] : sources,
        invalid: false,

        rules: allRules,
        onSubmit
      }
    }

    const sourceOfType = (type, label, getter, transform) => ({ label, type, getter: transform ? f => transform(getter(f)) : getter, hasValue: true, invalid: false })
    const ticketSource = (label, getter) => sourceOfType('ticket', label, getter)
    const ocrFieldSource = (fieldName, label, transform) => sourceOfType('ocr', label, ocr => ocr?.fields?.[fieldName]?.value, transform)
    const defaultSource = (val, label = '') => sourceOfType('default', label, typeof val === 'function' ? val() : () => val)

    const rules = {
      lengthLessEqual: (max, message) => v => v?.length <= max || message,
      numInRange: (low, high) => v => (v >= low && v <= high) || this.$t('numericValueBetween', { low, high }),

      validWeighedOutDate: date => {
        if (!date) return this.$t('invalidDate')
        const afterNow = date.localeCompare(new Date().toISOString()) === 1
        return afterNow ? this.$t('woaNotInFuture') : true
      },
      assertPositiveNetWeight: (message) => () => (parseInt(this.fieldSet.inWeight.value) < parseInt(this.fieldSet.outWeight.value)) ? message : true,
      assertPositiveInweightIfDefect: (message) => () => (parseInt(this.fieldSet.inWeight.value) === 0 && parseInt(this.fieldSet.defectWeight.value) > 0) ? message : true
    }

    const setNetWeight = () => {
      const { inWeight, outWeight, defectWeight } = this.fieldSet
      this.netWeight = (inWeight.value !== undefined && outWeight.value !== undefined && defectWeight !== undefined)
        ? inWeight.value - outWeight.value - defectWeight.value
        : undefined
    }

    this.fieldSet = {
      extTicketNumber1: makeField({
        required: true,
        label: 'extTicketNumber1',
        type: String,
        sources: ocrFieldSource('extTicketNumber1'),
        rules: [rules.lengthLessEqual(32, this.$t('ext1LengthLessEqualThan', { length: 32 }))]
      }),
      inWeight: makeField({
        required: true,
        label: 'inWeight',
        type: Number,
        sources: ocrFieldSource('inWeight'),
        rules: [rules.numInRange(0, 150_000), rules.assertPositiveNetWeight(this.$t('inWeightGtOutWeight'))],
        onSet: [this.validateFields, setNetWeight]
      }),
      outWeight: makeField({
        required: true,
        label: 'outWeight',
        type: Number,
        sources: ocrFieldSource('outWeight'),
        rules: [rules.numInRange(0, 150_000), rules.assertPositiveNetWeight(this.$t('outWeightLtInWeight'))],
        onSet: [this.validateFields, setNetWeight]
      }),
      defectWeight: makeField({
        required: false,
        label: 'defectWeight',
        type: Number,
        sources: defaultSource(0),
        rules: [rules.numInRange(0, 150_000), rules.assertPositiveInweightIfDefect(this.$t('defectWeightWithoutInWeight'))],
        onSet: setNetWeight
      }),
      weighedOutAt: makeField({
        required: true,
        label: 'weighedOutAt',
        type: Date,
        sources: [
          ocrFieldSource('weighedOutAt', this.$t('recognizedValue')),
          ticketSource(this.$t('loadCreatedAt'), ticket => {
            if (ticket?.loadCreatedAt === undefined) return undefined
            const loadCreated = moment.utc(ticket.loadCreatedAt)
            return loadCreated.local().format('YYYY-MM-DDTHH:mm:ss') // wipe utc offset from iso string
          })
        ],
        rules: rules.validWeighedOutDate,
        onSubmit ({ ticket, contract, field }) {
          if (field.selectedSource.label === i18n.t('loadCreatedAt')) {
            const localTimeString = field.value.replace('Z', '')
            const weighedOutAt = moment(localTimeString).utc()

            ticket.weighedOutAt = weighedOutAt

            if (ticket.loadCreatedAt && moment(ticket.loadCreatedAt).isAfter(moment(weighedOutAt))) {
              ticket.loadCreatedAt = weighedOutAt
            }

            if (ticket.departedAt && moment(ticket.departedAt).isAfter(moment(weighedOutAt))) {
              ticket.departedAt = weighedOutAt
            }

            return
          }

          const offset = contract.destinationAccountTimeZoneOffset
          let mOffsetAdj = moment.utc(field.value).add(0 - offset, 'hour')
          mOffsetAdj = mOffsetAdj.local().isDST() ? mOffsetAdj.add(-1, 'hour') : mOffsetAdj

          ticket.weighedOutAt = mOffsetAdj.toISOString()
          if (moment.utc(ticket.loadCreatedAt).isAfter(mOffsetAdj)) {
            ticket.loadCreatedAt = mOffsetAdj.toISOString()
          }

          if (ticket.departedAt && moment.utc(ticket.departedAt).isAfter(mOffsetAdj)) {
            ticket.departedAt = mOffsetAdj.toISOString()
          }
        }
      })
    }

    this.resetFields()

    this.ticketsDataLoader.load()
  },

  methods: {
    ...mapActions('ticket', ['getTicketsWithExternalNumber', 'fetchTicketsForReconciliation', 'updateTicket']),
    ...mapActions('product', ['fetchProducts']),
    ...mapActions('contract', ['fetchContracts', 'getContract']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    chunk,
    utcToLocalDate,
    tickTock,
    lowercaseEquals (s, search) {
      return s.toLowerCase().indexOf(search.toLowerCase()) !== -1
    },
    intOrUndefined (v) {
      const parsed = parseInt(v)
      return isNaN(parsed) || Number.isNaN(parsed) ? undefined : parsed
    },
    async setupTicket (ticket) {
      if (!ticket || Object.keys(ticket).length === 0) return
      this.resetFields()

      this.showDatePicker = false
      this.$nextTick(() => {
        this.showDatePicker = true
      })

      await this.imageDataLoader.loadDelayed(250, ticket)

      setTimeout(() => {
        if (this.$refs.ext1Input) {
          this.$refs.ext1Input.focus()
        }
      }, 0)

      // Contract is expired or closed.
      const shouldFetchContract = !this.externalContracts.map(c => c.contractId).includes(ticket.contractId)

      if (shouldFetchContract) {
        const contract = await this.getContract(ticket.contractId)
        this.externalContracts.push(contract)
      }

      this.applyDataToFieldsOfType('ticket', ticket)
      this.applyDataToFieldsOfType('default')
      this.resetTicketContract(ticket)
    },

    matchFieldsAgainstContracts (contracts, fixedFields, ticketContract) {
      const filteredContracts = contracts.filter(c => {
        const { destinationAccountId, fromAccountId, contractAccountId, tractId } = fixedFields
        if (fromAccountId !== undefined && c.fromAccountId !== fromAccountId) return false
        if (destinationAccountId !== undefined && c.destinationAccountId !== destinationAccountId) return false
        if (contractAccountId !== undefined && c.accountId !== contractAccountId) return false
        if (tractId !== undefined && c.tractId !== tractId) return false
        return true
      })

      const fromAccountId = fixedFields.fromAccountId === undefined ? ticketContract.fromAccountId : undefined
      const destinationAccountId = fixedFields.destinationAccountId === undefined ? ticketContract.destinationAccountId : undefined
      const contractAccountId = fixedFields.contractAccountId === undefined ? ticketContract.accountId : undefined
      const tractId = fixedFields.tractId === undefined ? ticketContract.tractId : undefined

      const matchRank = (contract) => {
        let rank = 0
        if (fromAccountId !== undefined && contract.fromAccountId === fromAccountId) rank += 1
        if (destinationAccountId !== undefined && contract.destinationAccountId === destinationAccountId) rank += 1
        if (contractAccountId !== undefined && contract.accountId === contractAccountId) rank += 1
        if (tractId !== undefined && contract.tractId === tractId) rank += 1
        return rank
      }

      return filteredContracts.reduce((r, currentContract) => {
        const currentMatchRank = matchRank(currentContract)
        return currentMatchRank > r.maxRank
          ? { maxRank: currentMatchRank, bestMatchContract: currentContract }
          : r
      }, { maxRank: -1, bestMatchContract: undefined }).bestMatchContract
    },

    resetTicketContract (ticket) {
      if (this.hasTracts) {
        this.knownTicketFields.contractId = ticket.contractId
        this.knownTicketFields.tractId = ticket.tractId
        this.knownTicketFields.productId = ticket.productID
      } else {
        this.knownTicketFields.contractId = ticket.contractId
        this.knownTicketFields.fromAccountId = ticket.fromAccountId
        this.knownTicketFields.destinationAccountId = ticket.destinationAccountId
        this.knownTicketFields.contractAccountId = ticket.accountId
        this.knownTicketFields.productId = ticket.productID
      }
    },

    getTicketGroupingKeys (tickets) {
      const tracts = groupBy(tickets, t => t.tractId)
      const destinations = groupBy(tickets, t => t.destinationAccountId)

      return [
        {
          type: this.ticketGroupTypes.none,
          display: this.$t('allTickets'),
          id: -1,
          reconciled: new Set(),
          total: tickets.length
        },
        ...((this.hasTracts) ? Object.keys(tracts).map(key => ({
          type: this.ticketGroupTypes.tract,
          display: tracts[key]?.[0].tract,
          id: parseInt(key),
          reconciled: new Set(),
          total: tracts[key].length
        })) : []),
        ...((this.hasFromAccount) ? Object.keys(destinations).map(key => ({
          type: this.ticketGroupTypes.destinationAccount,
          display: destinations[key]?.[0].destination,
          id: parseInt(key),
          reconciled: new Set(),
          total: destinations[key].length
        })) : [])
      ]
    },

    ticketGroupIconFor (groupType) {
      switch (groupType) {
        case this.ticketGroupTypes.tract:
          return 'mdi-crosshairs-gps'
        case this.ticketGroupTypes.loggerAccount:
          return 'mdi-axe'
        case this.ticketGroupTypes.destinationAccount:
          return 'mdi-domain'
        default:
          return 'mdi-clipboard-outline'
      }
    },

    async setGroupingKey (groupingKey) {
      this.loading = true
      this.selectedGroupingKey = groupingKey
      const prevTicketId = this.currentTicket?.ticketId
      this.currentTickets = (groupingKey.type !== this.ticketGroupTypes.none)
        ? this.allTickets.filter(t => t[groupingKey.type] === groupingKey.id)
        : this.allTickets
      this.currentTickets = this.currentTickets.sort((a, b) => groupingKey.reconciled.has(a.ticketId) && !groupingKey.reconciled.has(b.ticketId))
      this.currentTicketIndex = groupingKey.reconciled.size
      if (this.currentTicket?.ticketId !== prevTicketId) {
        await this.setupTicket(this.currentTicket)
      }
      this.loading = false
    },

    validateFields () {
      if (this.$refs.formWrapper?.$refs?.ticketReconciliationForm) {
        this.$refs.formWrapper.$refs.ticketReconciliationForm.validate()
      }
    },

    isoDateToDateTime (isoDate) {
      isoDate = moment.utc(isoDate).toISOString(true)
      const [date, time] = isoDate.split('T')
      return { date, time }
    },

    resetFields () {
      if (this.$refs.formWrapper?.$refs?.ticketReconciliationForm) {
        this.$refs.formWrapper.$refs.ticketReconciliationForm.reset()
      }
      this.fieldSet.weighedOutAt.value = undefined
    },

    applyRecognizedFields (data) {
      const fields = data?.fields ?? {}
      for (const key of Object.keys(fields)) {
        const target = this.fieldSet[key]
        if (target === undefined) continue
        target.value = fields[key].value
      }
    },

    setSelectedImageData (data) {
      this.selectedImageData = data
      this.applyDataToFieldsOfType('ocr', data)
      this.validateExtTicketNumber1()
    },

    applyDataToFieldsOfType (type, data) {
      for (const key of Object.keys(this.fieldSet)) {
        for (const source of (this.fieldSet[key].sources ?? [])) {
          if (source.type === type) {
            const sourceValue = source.getter(data)
            source.hasValue = sourceValue !== undefined
            source.invalid = (source.hasValue)
              ? this.validateAgainstRules(sourceValue, this.fieldSet[key].rules)
              : false
          }
        }

        if (this.fieldSet[key].selectedSource?.type === type) {
          if (this.fieldSet[key].sources !== undefined) {
            this.setFieldSource(this.fieldSet[key], this.findFirstValidSource(this.fieldSet[key].sources))
          }
          this.fieldSet[key].value = this.fieldSet[key].selectedSource.getter(data)
        }
      }
    },

    async submitTicket () {
      const ticketToUpdate = JSON.parse(JSON.stringify(this.currentTicket))
      Object.keys(this.fieldSet).forEach(key => {
        if (ticketToUpdate[key] !== undefined) ticketToUpdate[key] = this.fieldSet[key].value
        if (this.fieldSet[key].onSubmit) this.fieldSet[key].onSubmit({ ticket: ticketToUpdate, field: this.fieldSet[key], fieldSet: this.fieldSet, contract: this.selectedContract })
      })
      ticketToUpdate.contractId = this.knownTicketFields.contractId
      ticketToUpdate.productID = this.knownTicketFields.productId
      await this.updateTicket(ticketToUpdate)
      return ticketToUpdate
    },

    async advancePage () {
      this.loading = true
      this.currentTicketIndex += 1
      if (this.currentTicketIndex >= this.currentTickets.length) {
        if (this.ticketGroupingKeys.every(tg => tg.reconciled.size >= tg.total)) this.$emit('refresh')
      }
      this.clickedThrough = true
      await this.setupTicket(this.currentTickets?.[this.currentTicketIndex] ?? {})
      this.loading = false
    },

    updateGroupingKeys () {
      for (const tg of this.ticketGroupingKeys) {
        if (tg.type === this.ticketGroupTypes.none || this.currentTicket[tg.type] === tg.id) {
          tg.reconciled.add(this.currentTicket.ticketId)
        }
      }
    },

    submitAndAdvance () {
      const focusInvalidInput = this.invalidRefFocusHandle()
      if (focusInvalidInput !== undefined) {
        focusInvalidInput()
        return
      }

      this.submitTicket()
        .then(ticketUpdate => {
          this.clearExt1Check(this.selectedDestinationAccountId, ticketUpdate.extTicketNumber1)
          this.updateGroupingKeys()
          this.advancePage()
        })
        .catch((e) => {
          console.error(e)
        })
    },

    sortFieldsByDataQuality (a, b) {
      const dataQualityOf = (fields) => {
        if (fields === undefined) return 0
        const numFields = Object.keys(fields).length
        const avgConfidence = Object.keys(fields)
          .filter(key => fields[key] !== undefined && fields[key] !== null)
          .map(key => fields[key]?.confidence ?? 0)
          .reduce((p, c) => p + c, 0) / (numFields || 1)
        return numFields + avgConfidence // [0, 1, 2, 3, ...) + [0.0 .. 1.0] = [0.0 .. 1.0 ...)
      }
      const qualityOfA = dataQualityOf(a?.fields)
      const qualityOfB = dataQualityOf(b?.fields)
      return qualityOfB - qualityOfA
    },

    findFirstValidSource (sources) {
      return sources.find(s => s.hasValue && !s.invalid) ?? sources[0]
    },

    validateAgainstRules (value, rules) {
      for (const rule of (rules ?? [])) {
        const rr = rule(value)
        if (rr !== true) return rr
      }
      return false
    },

    async validateTicketNumberField () {
      const field = this.fieldSet.extTicketNumber1
      if (!this.currentTicket || !field.value) return
      const extTicketNumber1 = field.value
      const response = await this.getTicketsWithExternalNumber({
        extTicketNumber1,
        destinationAccountId: this.selectedDestinationAccountId,
        isByproduct: this.contractMode === ContractMode.Byproducts,
        isLogYardSale: this.contractMode === ContractMode.LogYardSale
      })
      const duplicateTickets = response.filter(t => t.ticketId !== this.currentTicket.ticketId)
      this.ext1Checks.push({ destinationAccountId: this.selectedDestinationAccountId, ext1: extTicketNumber1, duplicates: duplicateTickets })
    },

    clearExt1Check (destinationAccountId, extTicketNumber1) {
      const existingIndex = this.ext1Checks.findIndex(ec => ec.destinationAccountId === destinationAccountId && ec.ext1 === extTicketNumber1)
      if (existingIndex !== -1) {
        this.ext1Checks.splice(existingIndex, 1)
      }
    },

    setWarnings (warnings) {
      this.warnings = warnings
    },

    setClickedThrough (clickedThrough) {
      this.clickedThrough = clickedThrough
    },

    setFieldDate (field, date) {
      const [ymd] = date.split('T', 2)
      field.value = `${ymd}T${field.value.split('T')[1]}`
      field.invalid = this.validateAgainstRules(field.value, field.rules)
    },

    setFieldTime (field, time) {
      const [hours, minutes] = time.split(':', 3)

      field.value = moment.utc(field.value)
        .hours(parseInt(hours))
        .minutes(parseInt(minutes))
        .utc()
        .toISOString()

      field.invalid = this.validateAgainstRules(field.value, field.rules)
    },

    setFieldSource (field, source) {
      if (source === null) { // nasty hack to force v-select to select a value
        this.tickTock(
          () => { field.selectedSource = undefined },
          () => { field.selectedSource = this.findFirstValidSource(field.sources) }
        )
        return
      }

      field.selectedSource = source
      field.value = undefined
      this.$nextTick(() => {
        field.value = field.selectedSource
          ? field.selectedSource.getter((field.selectedSource.type === 'ocr') ? this.selectedImageData : this.currentTicket)
          : undefined
      })
    },

    async deleteTicketImage (imageUrl) {
      await ticketImageClient.deleteImage(imageUrl)

      const { pathname } = imageUrl instanceof URL ? imageUrl : new URL(imageUrl)
      const idx = this.imageData.findIndex(({ resourceUri }) => new URL(resourceUri).pathname === pathname)
      if (idx !== -1) {
        this.imageData = [
          ...this.imageData.slice(0, idx),
          ...this.imageData.slice(idx + 1)
        ]
        if (this.imageData.length === 0) {
          this.updateGroupingKeys()
          this.advancePage()
        }
      }
    },

    contractFields (contract) {
      return contract !== undefined ? [
        {
          value: contract.destination,
          icon: 'mdi-domain',
          tooltip: 'destinationAccount'
        },
        {
          value: contract.setting,
          icon: 'mdi-map-marker-circle',
          tooltip: 'setting'
        },
        {
          value: contract.account,
          icon: 'mdi-account',
          tooltip: 'account'
        }
      ] : []
    },

    async validateExtTicketNumber1 () {
      if (!this.fieldSet.extTicketNumber1.value || !this.ext1Unchecked || !this.selectedDestinationAccountId) return
      await this.ext1Validator.load()
    },

    openTicketDuplicatesDialog () {
      this.openOrUpdateDialog({ id: this.dialogId, width: '70vw', fullscreen: true })
    },

    invalidRefFocusHandle () {
      if (this.$refs.tractInput?.hasError) return () => this.$refs.tractInput.focus()
      if (this.$refs.contractInput?.hasError) return () => this.$refs.contractInput.focus()
      if (this.$refs.fromAccountInput?.hasError) return () => this.$refs.fromAccountInput.focus()
      if (this.$refs.destinationAccountInput?.hasError) return () => this.$refs.destinationAccountInput.focus()
      if (this.$refs.contractAccountInput?.hasError) return () => this.$refs.contractAccountInput.focus()
      if (this.$refs.productInput?.hasError) return () => this.$refs.productInput.focus()
      if (this.$refs.ext1Input?.hasError) return () => this.$refs.ext1Input.focus()
      if (this.$refs.inWeightInput?.hasError) return () => this.$refs.inWeightInput.focus()
      if (this.$refs.outWeightInput?.hasError) return () => this.$refs.outWeightInput.focus()
      if (this.$refs.defectWeightInput?.hasError) return () => this.$refs.defectWeightInput.focus()
      if (this.$refs.weighedOutAtInput?.invalid) {
        return () => {
          this.tickTock(
            () => { this.focusWeighedOutAtKey = false },
            () => { this.focusWeighedOutAtKey = true }
          )
        }
      }
    }
  }
}
</script>
